exports.components = {
  "component---src-pages-initnetlifyform-index-js": () => import("./../../../src/pages/initnetlifyform/index.js" /* webpackChunkName: "component---src-pages-initnetlifyform-index-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/Archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-cseriti-box-js": () => import("./../../../src/templates/CseritiBox.js" /* webpackChunkName: "component---src-templates-cseriti-box-js" */),
  "component---src-templates-form-page-js": () => import("./../../../src/templates/FormPage.js" /* webpackChunkName: "component---src-templates-form-page-js" */),
  "component---src-templates-hazhoz-js": () => import("./../../../src/templates/Hazhoz.js" /* webpackChunkName: "component---src-templates-hazhoz-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-karacsony-2023-page-js": () => import("./../../../src/templates/Karacsony2023Page.js" /* webpackChunkName: "component---src-templates-karacsony-2023-page-js" */),
  "component---src-templates-karacsony-page-js": () => import("./../../../src/templates/KaracsonyPage.js" /* webpackChunkName: "component---src-templates-karacsony-page-js" */),
  "component---src-templates-karrier-page-js": () => import("./../../../src/templates/KarrierPage.js" /* webpackChunkName: "component---src-templates-karrier-page-js" */),
  "component---src-templates-kisokos-page-js": () => import("./../../../src/templates/KisokosPage.js" /* webpackChunkName: "component---src-templates-kisokos-page-js" */),
  "component---src-templates-leadoknak-js": () => import("./../../../src/templates/Leadoknak.js" /* webpackChunkName: "component---src-templates-leadoknak-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/Legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-shops-js": () => import("./../../../src/templates/Shops.js" /* webpackChunkName: "component---src-templates-shops-js" */),
  "component---src-templates-single-job-js": () => import("./../../../src/templates/SingleJob.js" /* webpackChunkName: "component---src-templates-single-job-js" */),
  "component---src-templates-tarsadalmi-js": () => import("./../../../src/templates/Tarsadalmi.js" /* webpackChunkName: "component---src-templates-tarsadalmi-js" */),
  "component---src-templates-vallalatoknak-js": () => import("./../../../src/templates/Vallalatoknak.js" /* webpackChunkName: "component---src-templates-vallalatoknak-js" */)
}

